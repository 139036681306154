<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
            :label="$t('Ecommerce & Marketplace Integrations')"
            text-class="text-h6"
        />

        <search
            dense
            autoset
            is-expandable
            @submit="handleSearch"
        />

        <q-space/>

        <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="toggleFilters"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Refresh')"
            size="sm"
            class="q-mr-sm"
            no-caps
            unelevated
            @click="refreshItems"
        />

        <q-btn
            color="light-blue-9"
            text-color="white"
            :label="$t('Add integration')"
            size="sm"
            no-caps
            unelevated
            @click="create()"
        />
      </q-card-section>

      <filter-collapse
          :is-open="isOpen"
          :options="{
          defaultFilter: serverParams.filter,
          fields: activatedFields,
          values: {
            states: statuses
          },
          style: {
            noGroups: true
          }
        }"
          @submit="handleFiltersSubmit"
          @close="toggleFilters"
      />

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
            :style="sources.length > 0 ? `height: calc(100vh - 130px);` : ''"
            class="sticky-header-table"
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="sources"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="sourcesLoading"
            :filter="filter"
            virtual-scroll
            binary-state-sort
            flat
            @request="onRequest"
        >
          <template v-slot:body="props">
            <q-tr
                :props="props"
                class="clickable"
                @dblclick="onRowDblClick(props.row)"
            >
              <q-td
                  key="handler"
                  :props="props"
                  style="max-width: 100px;"
              >
                <img
                    v-if="props.row._embedded?.integration?.image"
                    :src="getLogoUrl(props.row._embedded.integration.image)"
                    style="width: 100px; object-fit: contain;"
                >

                <strong v-else class="text-h5">{{ props.row.handler }}</strong>
              </q-td>

              <q-td
                  key="id"
                  :props="props"
                  class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                  key="name"
                  :props="props"
              >
                <strong>{{ props.row.name || '--' }}</strong>
              </q-td>

              <q-td
                  key="created"
                  :props="props"
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>

              <q-td
                  key="state"
                  :props="props"
              >
                <q-toggle
                    color="light-blue-9"
                    :model-value="props.row.state === 'active'"
                    @update:model-value="handleStateChange(props.row)"
                />
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import Search from '../../components/search/Search.vue'
import FilterCollapse from '../../components/filters/FilterCollapse.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Mixins
import TableMixin from './../../components/global/TableMixin'

// Helpers
import { getLogoUrl } from './../../helpers/helpers'

// Store
import { store } from '../../vueX/store'

export default {
  name: 'Sources',
  components: {
    Search,
    FilterCollapse
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      isOpen: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Handler'),
          name: 'handler',
          align: 'center'
        },
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        }
      ],
      stateColors: {
        active: 'success',
        inactive: 'dark text-white'
      },
      statuses: [
        { id: 'active', title: this.$t('Active') },
        { id: 'inactive', title: this.$t('Inactive') }
      ],
      activatedFields: [
        'id',
        'name',
        'owner',
        'created.from',
        'created.to',
        'state'
      ],
      integrationSet: null,
      integrationHandler: null
    }
  },
  computed: {
    ...mapGetters([
      'sources',
      'totalSourcesNumber',
      'sourcesLoading',
      'appOptions'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ') + this.totalSourcesNumber
          : this.$t('Filter')
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    getLogoUrl,
    ...mapActions([
      'loadSources'
    ]),
    ...mapMutations([
      'setSource'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleFiltersSubmit (filter) {
      this.isOpen = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    toggleFilters () {
      this.isOpen = !this.isOpen
    },
    handleStateChange (integration) {
      const invertedState = {
        active: 'inactive',
        inactive: 'active'
      }

      const state = invertedState[integration.state]

      return this.$service.iSource.save({ state }, integration.id)
          .then(newItem => {
            const items = this.sources.map(item => {
              return item.id === newItem.id
                  ? newItem
                  : item
            })

            this.setSources(items)
          })
    },
    loadDefaultItems () {
      return this.onRequest({})
          .then(({ totalItems }) => {
            if (totalItems <= 0) {
              this.create()
            }
          })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadSources(this.serverParams)
          .then(({ items, totalPages, page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            if (this.serverParams?.filter[1]?.value) {
              this.integrationSet = items[0]?._embedded?.integration?.id
              this.integrationHandler = this.serverParams?.filter[1]?.value
            }

            return { items, totalPages, page, totalItems }
          })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    create () {
      if (this.integrationSet) {
        this.$router.push(`/apps/ecommerce/entity/${this.integrationHandler}/${this.integrationSet}/0/`)
      } else if (this.integrationHandler) {
        this.$router.push(`/apps/ecommerce/entity/${this.integrationHandler}/${this.integrationHandler}/0/`)
      } else {
        this.$router.push('/apps/ecommerce/entity')
      }
    },
    onRowDblClick (row) {
      if (!row._embedded?.integration?.id) {
        console.log(row)

        return
      }

      const routes = {
        default: () => {
          this.setSource(row)
          this.$router.push(`/apps/ecommerce/entity/default/${row._embedded.integration.id}/${row.id}`)
        },
      }

      if (routes[row.handler]) {
        routes[row.handler]()

        this.$router.push(`/apps/ecommerce/entity/${row.handler}/${row._embedded.integration.id}/${row.id}`)
      } else {
        routes['default']()
      }
    }
  }
}
</script>
